import React from "react";
import theme from "theme";
import { Theme, Link, Image, Em, Strong, Text, LinkBox, Section, Span, Box, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdPermContactCalendar, MdTextsms, MdLink, MdPhonelinkSetup, MdFavorite } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"product"} />
		<Helmet>
			<title>
				Dropify.net for Creators Becoming Founders
			</title>
			<meta name={"description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:title"} content={"Dropify.net for Creators Becoming Founders"} />
			<meta property={"og:description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/cover.png?v=2021-12-12T04:10:19.294Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Section
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Header"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
			/>
			<Stack md-justify-content="space-between">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/product"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section padding="112px 0 0px 0" background="#000000" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="normal 900 50px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-font="normal 900 35px/1.3 --fontFamily-sans"
					md-font="--headline2"
					xl-font="normal 900 50px/1.2 --fontFamily-sans"
				>
					The{" "}
					<Span
						color="#FFC400"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						only
					</Span>
					{" "}mobile-first bio link for creators that care about business results.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					We start our early access cohort soon, then close doors to continue development with early adopters like you.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="#register"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Get Early Access + Free Trial
					</Link>
				</Box>
				<Components.QuarklycommunityKitTimer
					font="--headline3"
					showDays
					showTextDone="never"
					toDate="30.04.2022"
					text-align="left"
					margin="40px 0px 0px 0px"
					showMinutes
				>
					<Override
						slot="Item"
						color="#ffffff"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="left"
						lg-text-align="center"
					/>
					<Override slot="Title" sm-font="--base" />
				</Components.QuarklycommunityKitTimer>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/lv_0_20220319111014.gif?v=2022-03-19T15:21:49.232Z"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" background="#000000">
			<Override slot="SectionContent" sm-flex="1 1 0%" />
			<Box
				align-items="center"
				display="flex"
				justify-content="flex-start"
				flex-direction="row"
				align-self="auto"
				flex="0 1 auto"
				sm-width="100%"
				sm-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-display="flex"
					sm-align-items="center"
					sm-justify-content="center"
					display="flex"
					align-items="center"
				>
					<Text
						margin="0px 40px 0px 0px"
						font="--headline2"
						color="--light"
						text-align="center"
						display="flex"
						sm-font="normal 900 35px/1.3 --fontFamily-sans"
						sm-margin="0px 0 0px 0px"
					>
						Trusted By{" "}
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-width="100%"
					sm-justify-content="center"
					display="flex"
				>
					<Image
						src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/Coda-Logo-White.png?v=2022-03-18T21:11:53.874Z"
						display="block"
						flex="0 1 auto"
						height="100px"
						margin="0px 20px 0px 20px"
						width="50px"
					/>
					<Image
						src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/twitterlogo.png?v=2022-03-18T21:11:42.672Z"
						display="block"
						flex="0 1 auto"
						width="50px"
						height="100px"
						margin="0px 20px 0px 20px"
					/>
					<Image
						src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/TikTok-logo-RGB-Horizontal-white-simplified.png?v=2022-03-22T09:43:32.661Z"
						display="block"
						flex="0 1 auto"
						height="100px"
						margin="0px 0px 0px 0px"
						width="150px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="100%"
				lg-padding="0px 50px 0px 0px"
				lg-width="100%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--projectManagement"
					color="--dark"
					md-text-align="center"
					sm-font="--headline2"
					padding="100px 0px 0px 0px"
				>
					Benefits
				</Text>
				<Text
					margin="50px 0px 50px 0px"
					font="--headline3"
					color="--dark"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Increased Performance{" "}
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="100%"
					display="flex"
					md-flex-direction="column"
					md-align-items="center"
				>
					<Box
						padding="15px 15px 15px 15px"
						align-items="center"
						display="flex"
						justify-content="center"
						flex-direction="column"
						lg-width="50%"
						sm-width="100%"
						width="33%"
					>
						<Text margin="0px 0px 16px 0px" font="--headline1" color="--dark">
							90%+
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--greyD2" text-align="center">
							Open rates
						</Text>
					</Box>
					<Box
						padding="15px 15px 15px 15px"
						display="flex"
						align-items="center"
						justify-content="center"
						flex-direction="column"
						lg-width="50%"
						sm-width="100%"
						width="33%"
					>
						<Text margin="0px 0px 16px 0px" font="--headline1" color="--dark">
							30%+
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--greyD2" text-align="center">
							Click through rate
						</Text>
					</Box>
					<Box
						padding="15px 15px 15px 15px"
						display="flex"
						align-items="center"
						justify-content="center"
						lg-width="50%"
						sm-width="100%"
						flex="0 1 auto"
						flex-direction="column"
						width="33%"
						md-display="flex"
					>
						<Text font="--headline1" color="--dark" text-align="center" margin="0 0px 16px 0px">
							10-20%
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--greyD2" text-align="center">
							Conversion rate
						</Text>
					</Box>
					<Box
						padding="15px 15px 15px 15px"
						display="flex"
						align-items="center"
						justify-content="center"
						lg-width="50%"
						sm-width="100%"
						flex="0 1 auto"
						flex-direction="column"
						width="33%"
						md-display="flex"
					>
						<Text font="--headline1" color="--dark" text-align="center" margin="0 0px 16px 0px">
							2x
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--greyD2" text-align="center">
							List building
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				flex-direction="column"
				lg-width="100%"
				md-width="100%"
				padding="60px 0px 0px 0px"
				md-padding="0 0px 0px 0px"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Icon
						category="md"
						icon={MdPermContactCalendar}
						size="64px"
						margin="0 0px 0px 0px"
						color="--secondary"
						padding="0px 20px 0px 0px"
					/>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Build Your Member List Faster
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Our technology helps you grow your list faster than traditional web forms and funnels.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Icon
						category="md"
						icon={MdTextsms}
						size="64px"
						margin="0 0px 0px 0px"
						color="--secondary"
						padding="0px 20px 0px 0px"
					/>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							SMS Is Better Than Email
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Own your audience that actually reads every message and takes action on your links.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 35px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Icon
						category="md"
						icon={MdLink}
						size="64px"
						flex-direction="row"
						align-content="flex-start"
						margin="0 0px 0px 0px"
						color="--secondary"
						padding="0px 20px 0px 0px"
					/>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Compatible With Other Bio Links
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Use it alone, or use it with other bio links to boost your business results like better conversions, faster list building, and customer research.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Icon
						category="md"
						icon={MdPhonelinkSetup}
						size="64px"
						color="--secondary"
						padding="0px 20px 0px 0px"
					/>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Easy Setup
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Sign up, setup your bio hashtag links and welcome message, use in your social media profiles.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 35px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Icon
						category="md"
						icon={MdFavorite}
						size="64px"
						flex-direction="row"
						align-content="flex-start"
						margin="0 0px 0px 0px"
						color="--secondary"
						padding="0px 20px 0px 0px"
					/>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Know Your Audience{" "}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							No one likes surveys. With our technology we increase completion rates so you can monetize your insights.{" "}
							<br />
							<br />
							For example you can apply dynamic pricing to earn more of true market value and leave less $ on the table.
							<br />
							<br />
							You can also launch product collaborations with brands with unique market insights they wish they had.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="#register"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Get Early Access + Free Trial
					</Link>
				</Box>
			</Box>
			<Box min-width="100px" min-height="100px" />
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px 0px 24px 0px"
				color="--dark"
				font="--headline1"
				lg-text-align="center"
				sm-font="--headline2"
			>
				The Problem
			</Text>
			<Box
				display="flex"
				width="100%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				md-flex-direction="column"
			>
				<Image
					src="https://images.unsplash.com/photo-1642480511862-963a0a7b42fd?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="500px"
					max-width="480px"
					sm-min-height="100vw"
					md-display="flex"
					md-flex="0 1 auto"
					sm-display="flex"
					sm-flex="1 1 0%"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0px 0px 30px"
					height="100%"
					md-padding="0px 0px 0px 0"
				>
					<Text
						margin="40px 0px 40px 0"
						color="--darkL1"
						font="--lead"
						lg-text-align="left"
						text-align="left"
						display="block"
						flex="1 1 0%"
						position="static"
						align-self="center"
						order="1"
						padding="0px 0px 0px 0px"
						sm-width="100%"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							All bio links help you put links in one place for your viewers, fans, users to learn more about you.
						</Strong>
						<br />
						<br />
						If you want your bio link to drive business results you can see how this is a problem. Other bio links are optimized for browsing, not growing revenue and building a community.
						<br />
						<br />
						Each additional step you ask your visitors to do decreases your conversions.
					</Text>
					<Box
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						sm-text-align="center"
						justify-content="flex-start"
						align-items="center"
						flex-direction="row"
						width="100%"
					>
						<Link
							href="#register"
							padding="12px 24px 12px 24px"
							color="--dark"
							background="--color-secondary"
							text-decoration-line="initial"
							font="--lead"
							border-radius="8px"
							margin="0px 16px 0px 0px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							sm-margin="0px 0px 16px 0px"
							sm-text-align="center"
							hover-transition="background-color 0.2s ease-in-out 0s"
						>
							Get Early Access + Free Trial
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				align-self="auto"
				md-flex-direction="column-reverse"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 30px 0px 0px"
					display="flex"
					height="100%"
					flex-direction="column"
					flex="1 1 0%"
				>
					<Text
						margin="40px 0px 40px 0"
						color="--darkL1"
						font="--lead"
						lg-text-align="left"
						text-align="left"
						display="flex"
						flex="0 1 auto"
						position="static"
						align-self="center"
						order="0"
						padding="0px 30px 0px 0px"
					>
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Your followers that discover you on social make the commitment to click your bio link because they are invested to see what’s on the other side, but when asked to take any additional steps, they aren’t committed yet, second guess, or get distracted.
						</Span>
					</Text>
					<Box
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						sm-text-align="center"
						justify-content="flex-start"
						align-items="center"
						flex-direction="row"
						width="100%"
					>
						<Link
							href="#register"
							padding="12px 24px 12px 24px"
							color="--dark"
							background="--color-secondary"
							text-decoration-line="initial"
							font="--lead"
							border-radius="8px"
							margin="0px 16px 0px 0px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							sm-margin="0px 0px 16px 0px"
							sm-text-align="center"
							hover-transition="background-color 0.2s ease-in-out 0s"
						>
							Get Early Access + Free Trial
						</Link>
					</Box>
				</Box>
				<Image
					src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/funnel-chart-example.png?v=2022-03-19T03:11:28.312Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="300px"
					max-width="480px"
					sm-min-height="100vw"
					md-flex="1 1 0%"
				/>
			</Box>
			<Box
				display="flex"
				width="100%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				align-self="auto"
				md-flex-direction="column"
			>
				<Image
					src="https://images.unsplash.com/photo-1557200134-90327ee9fafa?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="300px"
					max-width="480px"
					sm-min-height="100vw"
					md-flex="1 1 0%"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					height="100%"
					padding="0px 0px 0px 30px"
					md-padding="0px 0px 0px 0"
				>
					<Text
						margin="40px 0px 40px 0"
						color="--darkL1"
						font="--lead"
						lg-text-align="left"
						text-align="left"
						display="block"
						flex="1 1 0%"
						position="static"
						align-self="center"
						order="1"
						padding="0px 0px 0px 0px"
					>
						As part of your funnel, you collect emails to help drive business results, like conversions, but they have a low open rate.
						<br />
					</Text>
					<Box
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						sm-text-align="center"
						justify-content="flex-start"
						align-items="center"
						flex-direction="row"
						width="100%"
					>
						<Link
							href="#register"
							padding="12px 24px 12px 24px"
							color="--dark"
							background="--color-secondary"
							text-decoration-line="initial"
							font="--lead"
							border-radius="8px"
							margin="0px 16px 0px 0px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							sm-margin="0px 0px 16px 0px"
							sm-text-align="center"
							hover-transition="background-color 0.2s ease-in-out 0s"
						>
							Get Early Access + Free Trial
						</Link>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				align-self="auto"
				md-flex-direction="column-reverse"
			>
				<Box min-width="100px" min-height="100px" padding="0px 30px 0px 0px" margin="40px 0px 40px 0px">
					<Text
						margin="0 0px 40px 0"
						color="--darkL1"
						font="--lead"
						lg-text-align="left"
						text-align="left"
						display="block"
						flex="1 1 0%"
						position="static"
						align-self="center"
						order="-1"
						padding="0px 30px 0px 0px"
					>
						With all the anonymous visitors browsing your bio link, it’s difficult to understand what your audience wants and if the analytics being created are just noise.
					</Text>
					<Box
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						sm-text-align="center"
						justify-content="flex-start"
						align-items="center"
						flex-direction="row"
						width="100%"
					>
						<Link
							href="#register"
							padding="12px 24px 12px 24px"
							color="--dark"
							background="--color-secondary"
							text-decoration-line="initial"
							font="--lead"
							border-radius="8px"
							margin="0px 16px 0px 0px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							sm-margin="0px 0px 16px 0px"
							sm-text-align="center"
							hover-transition="background-color 0.2s ease-in-out 0s"
						>
							Get Early Access + Free Trial
						</Link>
					</Box>
				</Box>
				<Image
					src="https://images.unsplash.com/photo-1621252179027-94459d278660?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="300px"
					max-width="480px"
					sm-min-height="100vw"
					md-flex="1 1 0%"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					text-align="center"
					sm-font="--headline2"
					width="100%"
					height="100%"
				>
					What makes us different
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL1" text-align="center" font="--lead">
					The{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						bio link
					</Strong>
					{" "}real estate{" "}
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						is precious.
					</Strong>
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 7px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="flex-start"
					height="100%"
				>
					<Image
						src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/lv_0_20220319111014.gif?v=2022-03-19T15:21:49.232Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						object-fit="cover"
						max-height="278px"
						display="flex"
						justify-content="flex-start"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Optimized for Business Results
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							We have designed our link in bio to be optimized for business results.{" "}
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								It takes just{" "}
								<Span
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
								>
									2 taps
								</Span>
							</Strong>
							{" "}for your viewers to take action and become members.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 7px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="flex-start"
					position="relative"
					min-height="540px"
					height="100%"
				>
					<Image
						src="https://images.unsplash.com/photo-1555895175-53d60a8f31e2?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							We Give Exactly What Fans Want
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Your viewers are committed to take action once. Where other links in bio use that first tap to share more information, we build your SMS list in the first tap, and give the visitor
							<Strong>
								{" "}
							</Strong>
							exactly the info what they were looking for.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 7px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="flex-start"
					min-height="540px"
					height="100%"
				>
					<Image
						src="https://images.unsplash.com/photo-1611746869696-d09bce200020?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							We Focus on SMS
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							With our focus on SMS, open rates are 90%+, CTR are 30%+, and conversions can be between 10-20%.
							<br />
							<br />
							<br />
							<br />
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Box
			display="flex"
			justify-content="center"
			flex-direction="row"
			width="100%"
			margin="0px 0px 40px 0px"
			sm-width="100%"
		>
			<Link
				href="#register"
				padding="12px 24px 12px 24px"
				color="--dark"
				background="--color-secondary"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="0px 16px 0px 0px"
				transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				sm-margin="0px 0px 16px 0px"
				sm-text-align="center"
				hover-transition="background-color 0.2s ease-in-out 0s"
				display="flex"
			>
				Get Early Access + Free Trial
			</Link>
		</Box>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" sm-padding="30px 0 30px 0" background="#000000">
			<Override slot="SectionContent" align-items="flex-start" />
			<Box
				margin="0px 0px 40px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 32px 0px"
				display="flex"
				flex-direction="row"
				align-items="center"
				sm-margin="0px 0px 10px 0px"
				justify-content="space-between"
				lg-align-items="center"
				lg-flex-direction="column"
				lg-justify-content="center"
			>
				<Text
					margin="0px 32px 0px 0px"
					font="--headline1"
					color="#ffffff"
					text-align="center"
					md-font="--headline2"
					lg-margin="0px 0px 12px 0px"
					flex="0 0 auto"
				>
					Features
				</Text>
			</Box>
			<Box
				width="100%"
				display="flex"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="32px"
				align-self="auto"
				position="static"
				left="200px"
			>
				<Box display="flex">
					<Box
						display="flex"
						margin="0px 0px 0px 0px"
						flex-wrap="wrap"
						width="100%"
						padding="100px 0px 64px 0px"
						align-items="flex-start"
						flex-direction="column"
						justify-content="center"
						md-padding="10px 0px 64px 0px"
					>
						<Text color="--light" font="--headline3" margin="0px 0px 35px 0px" md-font="--base">
							1 Textme Bio Link
						</Text>
						<Text color="--light" font="--headline3" margin="0px 0px 35px 0px" md-font="--base">
							Limited Hashtag Links
						</Text>
						<Text color="--light" font="--headline3" margin="0px 0px 35px 0px" md-font="--base">
							1 SMS Phone Number
						</Text>
						<Text color="--light" font="--headline3" margin="0px 0px 35px 0px" md-font="--base">
							CRM
						</Text>
						<Text color="--light" font="--headline3" margin="0px 0px 35px 0px" md-font="--base">
							Business Analytics
						</Text>
						<Text color="--light" font="--headline3" margin="0px 0px 35px 0px" md-font="--base">
							Pre-designed Customer Research Micro Surveys
						</Text>
						<Text color="--light" font="--headline3" margin="0px 0px 35px 0px" md-font="--base">
							Pay As You Go Personalized Text  Messages
						</Text>
					</Box>
				</Box>
				<Box
					sm-padding="15px 4px 15px 4px"
					display="flex"
					flex-direction="column"
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 60px"
				>
					<Box
						display="flex"
						flex-wrap="wrap"
						width="100%"
						background="--color-primary"
						border-width="1px"
						border-style="solid"
						border-radius="16px"
						padding="32px 15px 64px 15px"
						flex-direction="column"
						align-items="center"
						sm-padding="32px 5px 64px 5px"
					>
						<Text
							color="--light"
							font="--headline3"
							margin="0px 0px 0px 0px"
							lg-text-align="center"
							lg-font="normal 600 20px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							md-font="--base"
							height="72px"
							lg-height="64px"
						>
							Early Access
						</Text>
						<Box
							margin="0px 0px 28px 0px"
							background="rgba(247, 251, 255, 0.15)"
							border-radius="100%"
							height="40px"
							md-margin="0px 0px 15px 0px"
							sm-margin="0px 0px 14px 0px"
						>
							<Icon
								category="io"
								icon={IoMdCheckmark}
								width="40px"
								height="40px"
								background="rgba(247, 251, 255, 0.15)"
								border-radius="100%"
								color="#F7FBFF"
								margin="0px 0px 28px 0px"
							/>
						</Box>
						<Box
							margin="0px 0px 28px 0px"
							background="rgba(247, 251, 255, 0.15)"
							border-radius="100%"
							height="40px"
							md-margin="0px 0px 15px 0px"
							sm-margin="0px 0px 14px 0px"
						>
							<Icon
								category="io"
								icon={IoMdCheckmark}
								width="40px"
								height="40px"
								background="rgba(247, 251, 255, 0.15)"
								border-radius="100%"
								color="#F7FBFF"
								margin="0px 0px 28px 0px"
							/>
						</Box>
						<Box
							margin="0px 0px 28px 0px"
							background="rgba(247, 251, 255, 0.15)"
							border-radius="100%"
							height="40px"
							md-margin="0px 0px 15px 0px"
							sm-margin="0px 0px 14px 0px"
						>
							<Icon
								category="io"
								icon={IoMdCheckmark}
								width="40px"
								height="40px"
								background="rgba(247, 251, 255, 0.15)"
								border-radius="100%"
								color="#F7FBFF"
								margin="0px 0px 28px 0px"
							/>
						</Box>
						<Box
							margin="0px 0px 28px 0px"
							background="rgba(247, 251, 255, 0.15)"
							border-radius="100%"
							height="40px"
							md-margin="0px 0px 15px 0px"
							sm-margin="0px 0px 14px 0px"
						>
							<Icon
								category="io"
								icon={IoMdCheckmark}
								width="40px"
								height="40px"
								background="rgba(247, 251, 255, 0.15)"
								border-radius="100%"
								color="#F7FBFF"
								margin="0px 0px 28px 0px"
							/>
						</Box>
						<Box
							margin="0px 0px 28px 0px"
							background="rgba(247, 251, 255, 0.15)"
							border-radius="100%"
							height="40px"
							md-margin="0px 0px 15px 0px"
							sm-margin="0px 0px 14px 0px"
						>
							<Icon
								category="io"
								icon={IoMdCheckmark}
								width="40px"
								height="40px"
								background="rgba(247, 251, 255, 0.15)"
								border-radius="100%"
								color="#F7FBFF"
								margin="0px 0px 28px 0px"
							/>
						</Box>
						<Box
							margin="0px 0px 28px 0px"
							background="rgba(247, 251, 255, 0.15)"
							border-radius="100%"
							height="40px"
							md-margin="0px 0px 15px 0px"
							sm-margin="0px 0px 14px 0px"
						>
							<Icon
								category="io"
								icon={IoMdCheckmark}
								width="40px"
								height="40px"
								background="rgba(247, 251, 255, 0.15)"
								border-radius="100%"
								color="#F7FBFF"
								margin="0px 0px 28px 0px"
							/>
						</Box>
						<Box margin="0px 0px 0px 0px" background="rgba(247, 251, 255, 0.15)" border-radius="100%" height="40px">
							<Icon
								category="io"
								icon={IoMdCheckmark}
								width="40px"
								height="40px"
								background="rgba(247, 251, 255, 0.15)"
								border-radius="100%"
								color="#F7FBFF"
								margin="0px 0px 28px 0px"
							/>
						</Box>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="20px 0px 0px 0px" color="--lightD1" font="--base" text-align="center">
							Free during early access
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				sm-width="100%"
				justify-content="flex-start"
				flex-direction="row"
				width="100%"
				margin="0px 0px 0px 0px"
			>
				<Link
					href="#register"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 16px 0px"
					hover-transition="background-color 0.2s ease-in-out 0s"
					display="flex"
				>
					Get Early Access + Free Trial
				</Link>
			</Box>
		</Section>
		<Section padding="0px 0 0px 0" background="#000000">
			<Override slot="SectionContent" sm-flex="1 1 0%" />
			<Box
				align-items="center"
				display="flex"
				justify-content="flex-start"
				flex-direction="row"
				align-self="auto"
				flex="0 1 auto"
				sm-width="100%"
				sm-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					sm-width="100%"
					sm-display="flex"
					sm-align-items="center"
					sm-justify-content="center"
					display="flex"
					align-items="center"
				>
					<Text
						margin="0px 40px 0px 0px"
						font="--headline2"
						color="--light"
						text-align="center"
						display="flex"
						sm-font="normal 900 35px/1.3 --fontFamily-sans"
						sm-margin="0px 0 0px 0px"
					>
						Trusted By{" "}
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					sm-display="flex"
					sm-width="100%"
					sm-justify-content="center"
					display="flex"
				>
					<Image
						src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/Coda-Logo-White.png?v=2022-03-18T21:11:53.874Z"
						display="block"
						flex="0 1 auto"
						height="100px"
						margin="0px 20px 0px 20px"
						width="50px"
					/>
					<Image
						src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/twitterlogo.png?v=2022-03-18T21:11:42.672Z"
						display="block"
						flex="0 1 auto"
						width="50px"
						height="100px"
						margin="0px 20px 0px 20px"
					/>
					<Image
						src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/TikTok-logo-RGB-Horizontal-white-simplified.png?v=2022-03-22T09:43:32.661Z"
						display="block"
						flex="0 1 auto"
						height="100px"
						margin="0px 0px 0px 0px"
						width="150px"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			background="#ffffff"
			md-padding="96px 0 0px 0"
			sm-padding="72px 0 0px 0"
			color="--lightD1"
			padding="80px 0 80px 0"
		>
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 50px/1.2 --fontFamily-sans"
					lg-text-align="center"
					md-font="--headline2"
					xl-font="normal 900 50px/1.2 --fontFamily-sans"
					sm-text-align="center"
					sm-font="normal 900 35px/1.3 --fontFamily-sans"
				>
					The{" "}
					<Span
						color="#FFC400"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						only
					</Span>
					{" "}mobile-first bio link for creators that care about business results.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					We start our early access cohort soon, then close doors to continue development with early adopters like you.
				</Text>
				<Text margin="0px 0px 0px 0px" color="#0E1317" font="--base" sm-text-align="center">
					By using our service, you agree to our Terms of Use and Privacy Policy.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				/>
				<Components.QuarklycommunityKitTimer
					font="--headline3"
					showDays
					showTextDone="never"
					toDate="30.04.2022"
					text-align="left"
					margin="40px 0px 0px 0px"
					showMinutes
				>
					<Override
						slot="Item"
						color="--darkL1"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="left"
						lg-text-align="center"
					/>
					<Override slot="Title" color="--darkL1" sm-font="--base" />
					<Override slot="Value" color="--darkL1" />
				</Components.QuarklycommunityKitTimer>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				id="register"
				sm-flex-direction="column"
			>
				<Components.EmbedHtmljs customHtml="<iframe id=&quot;coda-form&quot; width=&quot;500&quot; height=&quot;950&quot; frameborder=&quot;0&quot; style=&quot;max-width: 100%;&quot; allow=&quot;fullscreen&quot;></iframe>" customJs="(async function(){const ttclid=localStorage.getItem('ttclid');let url=`https://coda.io/embed/fbXH4ShPnb`;if(ttclid){url=await fetch(`https://dev--pixel.dropify.link/context?referrer=${encodeURIComponent(document.referrer)}&pageUrl=${encodeURIComponent(window.location.href)}&ttclid=${ttclid}`).then(res=>res.json()).then(data=>`https://coda.io/embed/fbXH4ShPnb?context=${data}`)}else{url=`https://coda.io/embed/fbXH4ShPnb?context=${JSON.stringify({ ttclid: ttclid })}`}document.querySelector('#coda-form').src=url})()" />
			</Box>
		</Section>
		<Section
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Header"
			justify-content="center"
			margin="0 0 0 0"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack>
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/product"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem display="flex" quarkly-title="Logo" md-width="50%" width="50%">
					<Override slot="StackItemContent" align-items="center" justify-content="center" md-justify-content="flex-end" />
					<Link
						font="--base"
						href="/terms"
						color="#000000"
						text-decoration-line="initial"
						opacity="0.6"
					>
						Terms of Use & Privacy Policy
					</Link>
					{"        "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script async={true} place={"endOfHead"} rawKey={"61b58c1050b03822215b2eac"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-MV36QFQ');"}
			</script>
			<script place={"endOfHead"} rawKey={"6246a896ffca0fbab6cc4994"}>
				{"document.addEventListener(\"DOMContentLoaded\",(e)=>{const ttclid=new URLSearchParams(window.location.search).get('ttclid');if(localStorage.getItem('ttclid')&&!ttclid){return}localStorage.setItem('ttclid',ttclid)})"}
			</script>
		</RawHtml>
	</Theme>;
});